import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"categories",
        "id":"category_id",
        "subidname":"category_name",
        "options":["edit","insert","delete"],
        "nameSingle":"category",
        "nameMultiple":"categories",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"category_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            
        }
    }
}